import React, { useEffect } from "react";
import Navigation from "../components/navigation/navigation";
import Footer from "../components/footer/footer";

// CSS
import "../sass/page-specific/policy-styles.scss";
import StylesComponent from "../components/styles/styles-component";
import { HeadFC } from "gatsby";
import SEO from "../components/seo/seo";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";

const TermsConditions: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="policy-hero-outer-container">
            <div className="policy-hero-container-overlay">
               <div className="policy-hero-text-container container-width">
                  <h1>Terms & conditions</h1>
                  <p className="secondary-text">Read up on the important documents related to your bookings</p>
               </div>
            </div>
         </div>

         <div className="standard-container">
            <div className="standard-inner-container container-width">
               <Breadcrumbs location="terms-conditions"/>
               <p className="secondary-text dark">On this page you will find a list of past and present terms & conditions for the various event and experience types that we have and have had available. If you cannot find the exact name of you experience, then it is likely that it will fall under our standard terms & conditions. Please note that your terms and conditions must be read in conjunction with your booking agreement.</p>
            </div>
         </div>

         <div className="terms-conditions-container">
            <div className="terms-conditions-inner-container container-width">
               <h2>Current documents</h2>

               <div className="terms-conditions-document-wrapper">
                  <a className="document-card" target="_blank" href="https://prestige-vip-web-documents.s3.eu-west-2.amazonaws.com/terms-conditions/prestige-corporate-events-tcs-v3.1.3.pdf">
                     <span>
                        <i className="fa-regular fa-file-pdf"></i>
                        <p>Standard T&Cs</p>
                     </span>

                     <p className="last-updated">Valid from: 21st November 2024</p>
                  </a>
               </div>
               
               <br/>
               <br/>
               <br/>

               <h2>Past documents</h2>

               <div className="terms-conditions-document-wrapper">
                  <a className="document-card expired" target="_blank" href="https://prestige-vip-web-documents.s3.eu-west-2.amazonaws.com/terms-conditions/prestige-corporate-events-tcs-v3.1.2.pdf">
                     <span>
                        <i className="fa-regular fa-file-pdf"></i>
                        <p>Standard - V2.0.1</p>
                     </span>

                     <p className="last-updated">Valid until: 20th November 2024<br />Valid from: 04th February 2023</p>
                  </a>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default TermsConditions

export const Head: HeadFC = () => (
   <SEO
      title="Terms & conditions | Prestige VIP"
      metaDescription="Please ensure that you read up on our terms & conditions before you use our services and take out a booking."
      slug="/terms-conditions"
   />
)
